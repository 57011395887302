//scss 全局变量

$g-color-primary: #5066ee; //主颜色
$g-color-light-blue: #e2efff; //选中状态 ---- 浅蓝色
$g-color-border: #ecececff; //边框线  分割线
$g-color-error: #ff2d2d; //错误 重要提示
$g-color-warning: #fe802f; //警告
$g-color-green: #36b37e; //成功的绿
$g-color-white: #ffffff; //白色
$g-color-grey: #f4f6f8; //灰底按钮
$g-color-body-background: #fafafb; //全局背景色/hover状态下背景色/已选状态背景色

//文字颜色
$g-text-primary: #37383b; //主要的
$g-text-secondary: #b4bac6; //次要的
$g-text-placeholder: #b4bac6; //输入框提示

//导航栏、菜单栏
$g-nav-header-height: 70px; // 导航栏的高度
$g-home-menu-width: 240px; // 首页菜单宽度
$g-min-nav-header-width: 1200px; //

$g-main-max-width: 1920px; // 主体最大宽度
$g-main-min-width: 1440px; // 主体最小的宽度
$g-main-padding: 16px; // 主体的padding

// 隐藏默认滚动条
::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

p {
  margin: 0;
  white-space: pre-wrap;
}

// 在谷歌下移除input[number]的上下箭头
.input-number::-webkit-outer-spin-button,
.input-number::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0;
}

// 同行
@mixin row {
  display: flex;
}

// 同行，逆序
@mixin row-reverse {
  @include row;
  flex-direction: row-reverse;
}

// 同行，间隔均匀
@mixin row-space-between {
  @include row;
  justify-content: space-between;
}

// 同行，间隔均匀，竖直居中
@mixin row-space-between-ver-center {
  @include row-space-between;
  align-items: center;
}

// 同行，水平居中
@mixin row-hor-center {
  @include row;
  justify-content: center;
}

// 同行，竖直居中
@mixin row-ver-center {
  @include row;
  align-items: center;
}

// 同行，上下左右居中
@mixin row-center {
  @include row-hor-center;
  @include row-ver-center;
}

// 同列
@mixin column {
  @include row;
  flex-direction: column;
}

// 同列，逆序
@mixin column-reverse {
  @include row;
  flex-direction: column-reverse;
}

// 同列，间隔均匀
@mixin column-space-between {
  @include row-space-between;
  @include column;
}

// 同列，水平居中
@mixin column-hor-center {
  @include column;
  align-items: center;
}

// 同列，竖直居中
@mixin column-ver-center {
  @include column;
  justify-content: center;
}

// 同列，上下左右居中
@mixin column-center {
  @include column-hor-center;
  @include column-ver-center;
}

// 同行
.row {
  @include row;
}

// 同行，逆序
.row-reverse {
  @include row-reverse;
}

// 同行，间隔均匀
.row-space-between {
  @include row-space-between;
}

// 同行，间隔均匀，竖直居中
.row-space-between-ver-center {
  @include row-space-between-ver-center;
}

// 同行，水平居中
.row-hor-center {
  @include row-hor-center;
}

// 同行，竖直居中
.row-ver-center {
  @include row-ver-center;
}

// 同行， 上下左右居中
.row-center {
  @include row-center;
}

// 同列
.column {
  @include column;
}

// 同列，逆序
.column-reverse {
  @include column-reverse;
}

// 同列，间隔均匀
.column-space-between {
  @include column-space-between;
}

// 同列，水平居中
.column-hor-center {
  @include column-hor-center;
}

// 同列，竖直居中
.column-ver-center {
  @include column-ver-center;
}

// 同列， 上下左右居中
.column-center {
  @include column-center;
}

// 文本溢出展示...，使用时需另外设置 -webkit-line-clamp控制展示行数
.text-ellipsis {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
}

// 单行文本，溢出展示...
.single-line {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  -webkit-line-clamp: 1;
}

// 文本不可选中
@mixin unselected {
  -moz-user-select: none; /* Firefox私有属性 */
  -webkit-user-select: none; /* WebKit内核私有属性 */
  -ms-user-select: none; /* IE私有属性(IE10及以后) */
  -khtml-user-select: none; /* KHTML内核私有属性 */
  -o-user-select: none; /* Opera私有属性 */
  user-select: none; /* CSS3属性 */
}

// 文本不可选中
.unselected {
  @include unselected;
}

// 鼠标hover时变成手指形态
.pointer {
  cursor: pointer;
}

//scss 全局变量
$nav-header-height: 70px; // 导航栏的高度
$home-menu-width: 240px; // 首页菜单宽度
$min-nav-header-width: 1200px; //

$size-xs: 5px;
$size-sm: 10px;
$size-normal: 15px;
$size-lg: 20px;
$size-xl: 25px;
$size-xxl: 30px;
$size-xxxl: 35px;

// 主体最大宽度
$main-max-width: 1920px;
// 主体最小的宽度
$main-min-width: 1280px;
// 主体的水平padding
$main-padding-horizontal: 24px;
// 主体的垂直padding
$main-padding-vertical: 8px;
// 全局通用阴影
$global-box-shadow: 0px 0px 40px 0px rgba(168, 173, 198, 0.15);

$page-padding: calc((100vw - 1280px) / 2);
$page-padding-mobile: calc((100vw - 343px) / 2);

$text-color-hilight: #f0802f;

.ant-select-selection-overflow-item-rest {
  .ant-select-selection-item {
    background-color: transparent;
  }
}

// rem 适配
$design-width: 390;
@function toRem($px) {
  @return calc($px / $design-width) * 10 + rem;
}

html {
  // 1rem
  font-size: 39px;
}

//滚动条样式
.g-scroll-bar {
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 4px;
    height: 8px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(181, 181, 195, 0.3);
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: rgba(181, 181, 195, 0.5);
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
  }
}

@mixin global-transition {
  transition: all 0.4s ease-in-out;
}

.download-guide-box {
  background: url("./assets/bg.png") no-repeat center;
  //background-size: 100% 100%;
  padding-bottom: 26px;
  .download-guide-title {
    color: #63728b;
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    text-align: center;
    margin-top: 18px;
  }
  .download-guide-content {
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;

    .download-item {
      width: 212px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 20px;
      background: #f8f9fb;
      padding: 30px 0;
      flex-direction: column;

      .ant-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 110px;
        height: 30px;
        border-radius: 8px;
        border: none;
        padding: 0;
      }

      .download-item-title {
        color: #37383b;
        font-size: 20px;
        font-weight: 700;
        line-height: 20px;
        margin-top: 16px;
      }
    }
  }
  .g-select.ant-select {
    min-width: unset;
    padding-right: 18px;
  }
  .ant-select-selection-item {
    color: #b7bfcb;
    font-size: 14px;
    padding-right: 0 !important;
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    padding: 8px;
  }
}

.down-select-drop-down-class {
  padding: 12px;
  min-width: 170px !important;
  width: fit-content !important;
  max-width: 200px !important;
  border-radius: 8px;
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    border-radius: 8px;
    background: linear-gradient(110deg, rgba(80, 101, 238, 0.04) -2.49%, rgba(209, 187, 255, 0.12) 57.08%, rgba(255, 239, 234, 0.16) 111.48%);
  }
  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background: linear-gradient(110deg, rgba(80, 101, 238, 0.04) -2.49%, rgba(209, 187, 255, 0.12) 57.08%, rgba(255, 239, 234, 0.16) 111.48%);
  }

  .ant-select-item-option {
    margin-bottom: 2px;

    &:last-child {
      margin-bottom: 0;
    }
  }
  .ant-select-item-option-content {
    padding: 0;
  }
  .recommend-badge {
    background: #e8f7ed;
    border-radius: 4px;
    padding: 4px;
    color: #36b37e;
    font-size: 14px;
    margin-left: 8px;
  }
}
